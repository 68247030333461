import { useEffect } from "react";
import "./styles/App.scss";

import { content } from "./content/content";
import LandingSection from "./LandingSection";
import ContentSection from "./ContentSection";
import Intro from "./Intro";
import React from "react";

const App = () => {

  const enableScrolling = () => {
    document.body.classList.add('loaded')
  }

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <>
      <LandingSection enableScrolling={enableScrolling}/>
      {content.map((section, i) => {
        return (
          <React.Fragment key={i}>
          {section.intro && <Intro intro={section.intro}/>}
          {section.textContent.length > 0 && <ContentSection key={i} section={section} />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default App;
