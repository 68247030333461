/* 
  To add a new section, create a section object to sectionContent array.
  Video frames are added to public/videos/{videoFolder}/ and named 0001.jpg, 0002.jpg, ...

  Section object:
  {
    videoFolder: string                 (mandatory)
    frameCount: int                     (mandatory)
    textColor: hex code                 (mandatory)
    slider: {                     (optional)
      startLabel: string
      endLabel: string
    }
    intro: [
      {
        backgroundColor: string
        title: string
        textColor: string
        content: [
          'text1',
          ...
          'textN'
        ]
      }
    ]
    textContent: [                      (mandatory but can be empty to render only static intro block)
      {
        title: string                   (optional)
        subtitle: string                (optional)
        description: string             (optional)
        interviewTitle: string          (optional)
        interviewDescription: string    (optional)
        links: [                        (optional)
          {
            url: string
            text: string
          }
        ]
        videoSrc: string                (optional)
      }, ...
    ]
  }

  Todo & Open questions:
  - progressbar / wood&concrete ratio? (add parameter to textcontent part object)
  - add videos
  - interact
*/

export const content = [
  // 1.0 INTRO
  {
    videoFolder: "1-intro",
    frameCount: 98,
    height: '600vh',
    textColor: "#FFFFFF",
    textContent: [
      {
        description:
          "City structures are 90% concrete and steel, and 10% structural timber.",
      },
      {
        description:
          "Should legislation force a change in that mix?",
      },
      {
        description:
          "Imagine urban planning, where all of us can play with the design parameters during the design process, and experience what would be different in our cities.",
      },
    ],
  },
  // 2.0 MATERIAL
  {
    videoFolder: "2-material",
    frameCount: 233,
    height: '1200vh',
    textColor: "#FFFFFF",

    slider: {
      startLabel: "Concrete & Steel",
      endLabel: "Wood",
      minValue: 10,
      maxValue: 80,
    },

    intro: {
      backgroundColor: "#FFE9B6",
      title: "Material",
      content: [
        "New advances in wood technology have created engineered material that can replace reinforced concrete in many ways. Modern design, engineering, and supply chains can make timber a part of almost any project.",
      ],
    },

    textContent: [
      {
        description: "After water, concrete is the most widely used substance on Earth.",
      },
      {
        description:
          "To build more with wood we need to ensure sustainable forestry and biodiversity.",
      },
      {
        description:
          "The supply chain for concrete is amazing. It’s available at a moment’s notice, everywhere.",
      },
      {
        description:
          "Wood is catching up. Keeping costs low has led us to repeat the same procedures for decades, at the cost of progress.",
      },
      {
        interviewTitle: "Roger Nickells",
        interviewDescription: "Buro Happold Civil Engineering visionary:\nThe Louvre, Abu Dhabi, Battersea Power Station Development, Mercedes-Benz Stadium, The High Line, etc.",
        videoSrc: "https://player.vimeo.com/video/566023596",
      },
      {
        description: "Reinforced concrete can adapt to any shape and withstand record levels of stress.",
      },
      {
        description:
          "New developments in wood technology like Laminated Veneer Lumber (LVL) and modular wood construction have made wood construction competitive.",
      },
      {
        interviewTitle: "Jasper Middelberg",
        interviewDescription: "Dutch based building engineer & designer of wood and reclaimed materials for interiors and small scale structures.",
        videoSrc: "https://player.vimeo.com/video/566023373",
      },
      {
        description:
          "Concrete and steel give that feeling of permanent structure! Maybe foundations in concrete but lightweight wood on top?",
      },
      {
        description:
          "Yes, Wood-hybrid structures can be built 35% faster and modular connections allow for fast renovations so living space can change around our needs.",
          links: [
            {
              url: "https://storage.googleapis.com/sidewalk-toronto-ca/wp-content/uploads/2019/06/23135715/MIDP_Volume2.pdf",
              text: "Link"
            }
          ]
      },
      {
        interviewTitle: "Jussi Björman",
        interviewDescription: "Director, Business Development, Construction at Metsä Wood",
        videoSrc: "https://player.vimeo.com/video/566023514",
      },
    ],
  },

  // 3.0 Economics
  {
    videoFolder: "3-economics",
    frameCount: 188,
    height: "1000vh",

    textColor: "#FFFFFF",

    slider: {
      startLabel: "Short term\n planning",
      endLabel: "Long term\n planning",
      minValue: 20,
      maxValue: 60,
    },

    intro: {
      backgroundColor: "#7DB1FF",
      textColor: "#000000",
      title: "Economic mindset",
      content: [
        "Governments are here for today’s and future generations.\nBut are our elected leaders really thinking beyond their term in office?",
        "Are developers incentivized to build for future generations or for near term profit?",
      ],
    },
    textContent: [
      {
        description:
          "Housing is being treated as a commodity rather than a social good.",
      },
      {
        description:
          "Many co-ops are fighting the growing threat of the of housing being turned into merely a financial asset, longer-term value could be generated in reforming zoning laws and more flexible renovation codes.",
      },
      {
        interviewTitle: "Elliot White",
        interviewDescription: "Service Design Lead & Resident Architect, Fjord part of Accenture Interactive",
        videoSrc: "https://player.vimeo.com/video/566062064",
      },
      {
        description:
          "The construction industry produces 1/3 of the total waste generation in the EU. We need longer life-cycles of materials and a stronger circular economy.",
      },
      {
        description:
          'Yes, and, many of the material streams from demolition and renovation works are not currently suitable for reuse or high-grade recycling. Can anyone say "financial opportunity?!"',
      },
      {
        interviewTitle: "Dan Hill",
        interviewDescription: "Director of Strategic Design at Vinnova. Visiting Prof at UCL Bartlett and Design Academy Eindhoven, Adjunct Prof at RMIT. Mayor of London Design Advocate.",
        videoSrc: "https://player.vimeo.com/video/566023248",
      },
    ],
  },

  // 4.0 Deciding power
  {
    videoFolder: "4-power",
    frameCount: 216,
    height: '1000vh',

    textColor: "#FFFFFF",

    slider: {
      startLabel: "Individual",
      endLabel: "Community",
      minValue: 12,
      maxValue: 70,
    },

    intro: {
      backgroundColor: "#396162",
      title: "Deciding power",
      textColor: "#FFFFFF",
      content: [
        "Who decides what resources are to be invested in the cities? And if we build, was it worthy? Was it a fair democratic decision? Who evaluates all this?",
      ],
    },

    textContent: [
      {
        description:
          "Cities are 3% of earth’s land mass but consume 70% of global energy. Our brains are 2% of our body mass and consume 20 percent of the body's energy.\nIsn’t centralization the natural way?",
      },
      {
        description:
          "We need a collective democratic global brain in order to tackle the complex problems ahead of us.\nThe exponential complexity is fast outpacing human cognitive ability.",
      },
      {
        description:
          "Connected cities offer many ways to increase knowledge sharing and community involvement in decision making. Early digital tools already exist that allow residents to meaningfully contribute to city planning.",
      },
      {
        interviewTitle: "Kaisa Herne",
        interviewDescription: "Finnish Professor of political science at the University of Tampere. Key research interests include experimental research in decision-making and political behavior, deliberative democracy, justice, and trust.",
        videoSrc: "https://player.vimeo.com/video/566023563",
      },
      {
        description: "Does top down decision making result in better AND faster decisions?\nCan we rely on the benevolent dictator?",
      },
      {
        description:
          "Cities are trying new governance models and new institutions are rising all the time for the monitoring of power distribution.",
      },
      {
        interviewTitle: "John Keane",
        interviewDescription: "Professor of Politics at the University of Sydney and at the Wissenschaftszentrum Berlin (WZB). Co-founder and director of the Sydney Democracy Network (SDN). Renowned globally for his creative thinking about democracy.",
        videoSrc: "https://player.vimeo.com/video/566023431",
      },
    ],
  },
  // 5.0 Interact
  {
    intro: {
      backgroundColor: "#426061",
      title: "Challenge the dominance of concrete and steel",
      textColor: "#FFFFFF",
      content: [
        "If sustainability is an issue we strongly believe in, then we must challenge the status quo of how our cities are made - and the dominance of concrete and steel.",
        "The Decisions.city experience was created in collaboration with Metsä Wood and Accenture Interactive, a way for ordinary people to inform themselves and vote on how they want their city to develop.",
        "Let’s let decision-makers know how we feel about the materials used to construct our cities. Let’s move away from an opaque rigid planning into a radically open city, embracing complexity to deeply understand how cities work.",
        "In Decisions.city you’ll cast your vote on what kind of city you want to have because, the kind of people we want to be is the kind of cities we should be designing for.",
        "Stay tuned for the next iteration, prototyping a citizen engagement tool that gives planners & municipalities a better ability to communicate the implications of choices between options..."
      ],
      images: [
        '/decisionscity2_1.png',
        '/decisionscity2_2.png',
        '/decisionscity2_3.png',
      ]
    },
    textContent: [],
  },
];
