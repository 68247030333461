import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import TextSection from "./TextSection";
import "./styles/ContentSection.scss";

const ContentSection = ({ section }) => {
  const { frameCount, videoFolder, textContent, textColor, slider, height } = section
  
  const sectionHeight = height ? height : Math.ceil(frameCount / 30) * 100 + "vh";

  const [images, setImages] = useState([]);
  const [percentScrolled, setPercentScrolled] = useState(0);

  const canvasRef = useRef();
  const containerRef = useRef();

  let context;
  let canvasHeight = window.screen.height;
  let canvasWidth = document.querySelector("#root").clientWidth;
  let img;

  const handleScroll = () => {
    const distanceFromTop = window.scrollY + containerRef.current.getBoundingClientRect().top;
    const rawPercentScrolled =
      (window.scrollY - distanceFromTop) / (containerRef.current.scrollHeight - window.innerHeight);
    const percent = Math.min(Math.max(rawPercentScrolled, 0), 1);

    const frameIndex = Math.max(Math.min(Math.floor(percent * frameCount), frameCount), 1);
    requestAnimationFrame(() => updateImage(frameIndex));
    setPercentScrolled(percent);
  };

  useEffect(() => {
    context = canvasRef.current.getContext("2d");
    window.addEventListener("resize", resizeImage);
    window.addEventListener("scroll", handleScroll);

    preloadImages();

    img = new Image();
    img.src = currentFrame(1);

    canvasRef.current.width = canvasWidth;
    canvasRef.current.height = canvasHeight;

    img.onload = function () {
      coverImg(img);
    };

    return () => {
      window.removeEventListener('resize', resizeImage)
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  const coverImg = (img) => {
    const imgRatio = img.height / img.width;
    const winRatio = canvasHeight / canvasWidth;

    if (imgRatio > winRatio) {
      const h = canvasWidth * imgRatio;
      context.drawImage(img, 0, (canvasHeight - h) / 2, canvasWidth, h);
    } else {
      const w = (canvasWidth * winRatio) / imgRatio;
      context.drawImage(img, (canvasWidth - w) / 2, 0, w, canvasHeight);
    }
  };

  const resizeImage = () => {
    //canvasHeight = window.innerHeight;
    canvasHeight = window.screen.height;
    canvasWidth = document.querySelector("#root").clientWidth;
    canvasRef.current.width = canvasWidth;
    canvasRef.current.height = canvasHeight;
    canvasRef.current.style.width = `${canvasWidth}px`;
    canvasRef.current.style.height = `${canvasHeight}px`;
    renderImage();
  };

  const renderImage = () => {
    coverImg(img);
    requestAnimationFrame(renderImage);
  };

  const preloadImages = () => {
    for (let i = 1; i <= frameCount; i++) {
      const img = new Image();
      const imgSrc = currentFrame(i);
      img.src = imgSrc;
      setImages((prevImages) => [...prevImages, img]);
    }
  };

  const currentFrame = (index) => `videos/${videoFolder}/${index.toString().padStart(4, "0")}.jpg`;

  const updateImage = (index) => {
    img.src = currentFrame(index);
    coverImg(img);
  };

  return (
    <div ref={containerRef} className="content-section-container" style={{ height: sectionHeight }}>
      <div className={classNames("content", { visible: textContent.length })}>
        <div className="overlay" style={{height: window.screen.height}}></div>
        <canvas ref={canvasRef} />
        <TextSection
          textContent={textContent}
          textColor={textColor}
          percentScrolled={percentScrolled}
          slider={slider}
        />
      </div>
    </div>
  );
};

export default ContentSection;
