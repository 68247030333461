import classNames from "classnames";
import "./styles/TextSection.scss";

const TextSection = ({ textContent, textColor, percentScrolled, slider }) => {
  const numberOfParts = textContent.length;
  const beforeStart = 0; // e.g. 0.05 to delay first appearing
  const afterEnd = 0.0; // e.g. 0.05 to delay last dissapearing
  const interval = (1 - beforeStart - afterEnd) / numberOfParts;
  const index = Math.min(Math.floor((percentScrolled - beforeStart) / interval), numberOfParts - 1);

  const isVisible = (i) => {
    if (index === i && percentScrolled > beforeStart && percentScrolled < 1 - afterEnd) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {textContent.map((part, i) => (
        <div key={i} className="text-section">
          <div
            className={classNames("text-container", { visible: isVisible(i) })}
            style={{ color: textColor }}
          >
            {part.title && <h2 className="title">{part.title}</h2>}
            {part.subtitle && <h4 className="subtitle">{part.subtitle}</h4>}
            {part.description && <p className="description">{part.description}</p>}

            {part.links && (
              <div className="links">
                {part.links.map((link, i) => (
                  <a
                    key={i}
                    className="link"
                    href={link.url}
                    target="__blank"
                    noreferrer="true"
                    noopener="true"
                    style={{ color: textColor }}
                  >
                    {link.text}
                  </a>
                ))}
              </div>
            )}

            {part.videoSrc && (
              <div className="video">
                <iframe
                  src={part.videoSrc}
                  title="Vimeo video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}

            {part.interviewTitle && <div className="interview-title">{part.interviewTitle}</div>}
            {part.interviewDescription && (
              <div className="interview-description">{part.interviewDescription}</div>
            )}
            
          </div>
        </div>
      ))}
      {slider && (
        <div className="slidecontainer">
          <input
            disabled
            type="range"
            min="0"
            max="100"
            value={Math.min(percentScrolled * 100 + slider.minValue, slider.maxValue)}
            className="slider"
            list="labels"
            style={{ backgroundColor: textColor }}
          />
          <div className="slider-labels" style={{ color: textColor }}>
            <h5 className="slider-label start">{slider.startLabel}</h5>
            <h5 className="slider-label end">{slider.endLabel}</h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextSection;
