import "./styles/Intro.scss";

const Intro = ({ intro }) => {
  const { title, content, backgroundColor, textColor, images } = intro;

  return (
    <div className="intro" style={{ backgroundColor: backgroundColor }}>
      <div className="text-container" style={{color: textColor}}>
        <h3 className="intro-title">{title}</h3>
        {content.map((text, i) => (
          <p key={i} className="intro-text">{text}</p>
        ))}
        {images?.map((imageSrc, i) => (
          <img loading="lazy" key={i} className="intro-image" src={imageSrc} />
        ))}
      </div>
    </div>
  );
};

export default Intro;
