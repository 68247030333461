import { useState } from "react";
import "./styles/LandingSection.scss";

const LandingSection = ({enableScrolling}) => {

  const [text, setText] = useState('Loading...')

  const onLoadedData = () => {
    enableScrolling()
    setText('Scroll for more')
  }

  return (
    <>
      <video onLoadedData={() => onLoadedData()} className="landing-video" autoPlay muted loop playsInline>
        <source src={"videos/title.mp4"} type="video/mp4" />
      </video>
      <div className="landing-text-container">
        <div className="wrapper">
          <h1 className="landing-title">Decisions<wbr/>.city</h1>
        </div>
      </div>

      <div className="c-scrolldown">
        <div className="c-line"></div>
      </div>
      <p className="scroll-text ">{text}</p>
      <div className="dot-pulse"></div>
    </>
  );
};

export default LandingSection;
